import React from 'react';
import ReactDOM from 'react-dom';
import GridRow from './GridRow';
import GridPager from './GridPager';
import CountryFilter from './filters/CountryFilter';
import SectorFilter from './filters/SectorFilter';
import RatingFilter from './filters/RatingFilter';
import RefreshFilter from "./filters/RefreshFilter";
import AnalysisModal from "./modals/AnalysisModal";

import { Container, Row, Col, Card, Button } from 'react-bootstrap';


export default class GridView extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            error: null,
            isLoaded: false,
            totalPage : 0,
            searchText: '',
            serachTypedText: '',
            currentPage : 1,
            pageSize : 20,
            List : [],
            country: '',
            countryText: 'All Countries',
            sector: '',
            sectorText: 'All Sectors', 
            technicalRating: '',
            selectedTicker: ''
        };

        this.onKeyPressedSearch = this.onKeyPressedSearch.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickPagination = this.onClickPagination.bind(this);
        this.onClickSector = this.onClickSector.bind(this);

     
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    }

    componentDidMount() {
      if( this.getCookie('searchTerm') ) {
        const searchTerm = this.getCookie('searchTerm');
        this.state.searchText = searchTerm;
        this.state.searchTypedText = searchTerm;
        document.cookie = "searchTerm=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
      }
      this.populateData();
      //document.getElementById("search-text-box").value = "fdsfsddfsa";
    }

    
    
    render = () => {
        const { error, isLoaded, totalPage, searchText, currentPage, pageSize, items } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {

          var rows = [];
          this.state.List.forEach( function( item ) {
            rows.push(
            <GridRow key={item.mst_assessment_direction_id} item={item} />);
          });

          ReactDOM.render(<input id="search-text-box" type="text" class="form-control bg-light border-0 small rounded" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2"  onKeyUp={this.onKeyPressedSearch}  />, document.getElementById('search-table'));
          

          ReactDOM.render(<button onClick={this.onClickSearch} class="btn btn-primary" type="button"><i class="fas fa-search fa-sm"></i></button>, document.getElementById('search-button'));
          
          ReactDOM.render(
            <div>
              <RatingFilter onRatingChanged={this.onClickRating} selected={this.state.technicalRating}  />
              
            <div role="group" class="dropdown btn-group" >
              <CountryFilter onSectorChanged={this.onClickCountry} selected={this.state.country} text={this.state.countryText} />
            </div>
            <div role="group" class="dropdown btn-group" >
              <SectorFilter onSectorChanged={this.onClickSector} selected={this.state.sector} text={this.state.sectorText} />
            </div>
            
            <div role="group" class="dropdown btn-group"   >
              <RefreshFilter key="btnRefresh" class="btnRefresh" onRefreshChanged={this.onClickRefresh} />
            </div>
            </div>
            ,
            document.getElementById('filter-table')
          );

          

          return (

            /*<div className="GridView" >
            <table class="table table-bordered dataTable" id="dataTable" width="95%" cellspacing="0" role="grid" aria-describedby="dataTable_info" >
              <thead>
                  <tr>
                      <th>
                          
                          
                      </th>
                      <th>
                          COUNTRY
                          
                      </th>
                      <th>
                          TICKER
                          
                      </th>
                      <th>
                          COMPANY
                          
                      </th>
                      <th>
                          SECTOR
                          
                        </th>
                        <th>
                            INDUSTRY
                            
                        </th>
                        <th>
                            RATED
                            
                        </th>
                    </tr>
                </thead>
                <tbody>*//*</tbody>
            </table>*/
            <Container className="stock-summary" >
              <Row>
              {rows}
              <Col sm={12} >
              <GridPager Size={this.state.totalPage} onPageChanged={this.onClickPagination} currentPage={this.state.currentPage} />
              </Col>
            </Row>
            </Container>
            
          /*</div>*/
          );
        }
    }

    populateData = () => {

      //set the search value if search is used from outside
      
      

      var params = {
        pageSize : this.state.pageSize,
        currentPage : this.state.currentPage,
        searchText : this.state.searchText, 
        country : this.state.country,
        sector : this.state.sector, 
        technicalRating : this.state.technicalRating, 
      };

      var url = this.props.dataUrl
      + '?searchText=' + params.searchText 
      + '&pageSize=' + params.pageSize 
      + '&currentPage=' + params.currentPage 
      + '&sector=' + params.sector 
      + '&country=' + params.country 
      + '&technicalRating=' + params.technicalRating 
      console.log(url);
      fetch( url )
        .then(res => res.json())
        .then(
          (result) => {
            if( params.currentPage == 1 ){
              this.setState({
                isLoaded: true,
                List: result.List, 
                totalPage: result.totalPage, 
              });
            } else {
              this.setState({
                isLoaded: true,
                List : this.state.List.concat( result.List ), 
                totalPage: result.totalPage
              });
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )    
    }

    onKeyPressedSearch = (searchText) => {
      this.state.searchTypedText = searchText.target.value;
      if( searchText.key === 'Enter' ) {
        this.state.searchText = this.state.searchTypedText;
        this.state.currentPage = 1;
        this.state.country = '';
        this.state.countryText = 'All Countries';
        this.state.sector = '';
        this.state.sectorText = 'All Sectors';
        this.state.currentPage = 1;
        this.state.technicalRating = '';
        this.populateData();
      }
    }

    onClickSearch = (searchText) => {
      this.state.searchText = this.state.searchTypedText;
      this.state.currentPage = 1;
      this.state.country = '';
      this.state.countryText = 'All Countries';
      this.state.sector = '';
      this.state.sectorText = 'All Sectors';
      this.state.currentPage = 1;
      this.state.technicalRating = '';
      this.populateData();
  
    }


    onClickPagination = (pageNumber,e) => {
      e.preventDefault();
      if( pageNumber == 'previous' ) {
        this.state.currentPage--;
      } else if( pageNumber == 'next' ) {
        this.state.currentPage++;
      } else {
        this.state.currentPage = pageNumber;
      }
        this.populateData();
    }

    onClickCountry = (country, countryText, e) => {
      e.preventDefault();
      console.log(country);
      this.state.country = country;
      this.state.countryText = countryText;
      this.state.currentPage = 1;
      this.state.searchText = '';
      this.state.searchTypedText = '';
      document.getElementById("search-text-box").value = "";
      this.populateData();
    }

    onClickSector = (sector, sectorText, e) => {
      e.preventDefault();
      console.log(sectorText);
      this.state.sector = sector;
      this.state.sectorText = sectorText;
      this.state.currentPage = 1;
      this.state.searchText = '';
      this.state.searchTypedText = '';
      document.getElementById("search-text-box").value = "";
      this.populateData();
    }

    onClickRating = (technicalRating, e) => {
      e.preventDefault();
      this.state.technicalRating = technicalRating;
      this.state.currentPage = 1;
      this.state.searchText = '';
      this.state.searchTypedText = '';
      document.getElementById("search-text-box").value = "";
      this.populateData();
    }

    onClickRefresh = (e) => {
      e.preventDefault();
      this.state.country = '';
      this.state.countryText = 'All Countries';
      this.state.sector = '';
      this.state.sectorText = 'All Sectors';
      this.state.currentPage = 1;
      this.state.technicalRating = '';
      this.state.searchText = '';
      this.state.searchTypedText = '';
      document.getElementById("search-text-box").value = "";
      this.populateData();
    }
}


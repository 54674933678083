import React from 'react';
import ReactDOM from 'react-dom';
import Chart from "chart.js";

export default class MarketOverview extends React.Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

        const buy = this.props.buy;
        const sell = this.props.sell;
        const caution = this.props.caution;
        
        this.state = {
            config: {
                type: "doughnut",
                
                data: {
                    labels: ['Buy', 'Sell', 'Caution'],
                    
                    datasets: [
                        {
                            label: "Overview",
                            data: [buy, sell, caution],
                            backgroundColor: ['#18cd57', '#f30131', '#eed202']
                            
                        }
                    ]
                },
                options: {
                    //Customize chart options
                    maintainAspectRatio: false,
                    tooltips: {
                      backgroundColor: "rgb(255,255,255)",
                      bodyFontColor: "#858796",
                      borderColor: '#dddfeb',
                      borderWidth: 1,
                      xPadding: 15,
                      yPadding: 15,
                      displayColors: false,
                      caretPadding: 10,
                    },
                    legend: {
                      display: true,
                      position:'left'
                    },
                    cutoutPercentage: 80,
                    },
                }
            
        };
    }

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");
        new Chart( myChartRef, this.state.config );
    }
    
    render = () => {
        return (
              <>  
              <canvas    
                    id="myMarketChart"
                    ref={this.chartRef}
                    width="572" height="545"
                    class="chartjs-render-monitor"
                    style={{display: 'block', height:'208px', width: '218px'}}
                />
               
                </>
        );
    }
}


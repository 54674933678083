import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';


export default class Header extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            serachTypedText: ''
        };
        this.onKeyPressedSearch = this.onKeyPressedSearch.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
    }

    render = () => {
        if( this.state.searchText ) {
            document.cookie = "searchTerm=" + this.state.searchText;
            window.location.replace("/stocks");
        }
        return (
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow row">
                <Col sm={12} className="text-center" >
                    <div class="input-group">
                        <div id="search-table" >
                            <input onKeyUp={this.onKeyPressedSearch}  type="text" class="form-control bg-light border-0 small " placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                        </div>
                        <div id="search-button" class="input-group-append">
                            <button class="btn btn-primary" type="button" onClick={this.onClickSearch}>
                                <i class="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </Col>
                
            </nav>
        );
    }

    onKeyPressedSearch = (searchText) => {
        this.state.searchTypedText = searchText.target.value;
        if( searchText.key === 'Enter' ) {
          this.state.searchText = this.state.searchTypedText;
          this.setState({
            searchText: searchText.target.value
          });
        }
      }
  
      onClickSearch = (searchText) => {
        this.setState({
            searchText: this.state.searchTypedText
          });
    }
}
import React from 'react';
import ReactDOM from 'react-dom';

export default  class Analysis extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    const name = this.props.item.display_name;
    const technicalRating = this.props.item.technical_rating_display;
    var ratingStrengthedRounded = parseInt(this.props.item.technical_rating_strength, 10);;
    if( this.props.item.technical_rating_strength ) {
        ratingStrengthedRounded = ratingStrengthedRounded + '%';
    } else {
        ratingStrengthedRounded = '';
    }
    const technical_rating = parseInt( this.props.item.technical_rating );
    var ratingStyle = '';
    var message = '';
    if( technical_rating == 5 || technical_rating == 4 ) {
        ratingStyle = 'colorGreen';
        message = 'it will go up';
    } else if( technical_rating == 1 ) {
        ratingStyle = 'colorRed';
        message = 'it will go down';
    } else if ( technical_rating == 3 ) {
        ratingStyle = 'colorYellow';
    } else {
        ratingStyle = '';
    }
    if( technical_rating == 5 || technical_rating == 4 || technical_rating == 1 || technical_rating == 2 ) {
        return (
            <span>
                <p><strong>{name}</strong> is a <span className={ratingStyle} >{technicalRating}!</span> </p>
                <p>There is a <span className={ratingStyle} >{ratingStrengthedRounded}</span> chance that {message} in the near term.</p> 
                <p>The chart shows the stock price on top of past ratings.</p>
            </span>
        );
    } else {
        return (
            <span>
                <p><strong>{name}</strong> has been issued a <span className={ratingStyle} >{technicalRating}</span> rating. </p>
                <p>The chart shows the stock price on top of past ratings.</p>
            </span>
        );
    }  
  }
}


import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import  MarketOverview  from './components/charts/MarketOverview';
import  SectorOverview  from './components/charts/SectorOverview';
import { Container, Row, Image, Col, Card, Button } from 'react-bootstrap';
import MSTCarousel from './components/MSTCarousel'


export default class Overview extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            buyGrandTotal:0, 
            sellGrandTotal:0, 
            cautionGrandTotal:0,
            sector: {
                buyTotals: [], 
                sellTotals: [], 
                labels:[]
            }
        }
    }

    
    componentDidMount() {
        //this.createSectorOverviewChart();
        this.createMarketOverviewPieChart();
    }

    createSectorOverviewChart = () => {
        const url = 'http://38.88.104.152/home/getReportBuySellHoldSectorTotals';
        fetch( url )
        .then(res => res.json())
        .then(
        (result) => {
            const List = result.List;
            var buyTotals = [];
            var sellTotals = [];
            var labels = [];
            var currentLabel = '';
            var buyTotal = 0;
            var sellTotal = 0;
            
            List.forEach( function( item ) {

                if( currentLabel != item.sector ) {
                    if( currentLabel != '') {
                        labels.push( currentLabel );
                        buyTotals.push( buyTotal );
                        sellTotals.push( sellTotal );
                    }
                    currentLabel = item.sector;
                    buyTotal = 0;
                    sellTotal = 0;
                }

                if( item.technical_rating == 1 ) {
                    sellTotal += item.total;
                } else if( item.technical_rating == 4 || item.technical_rating == 5 ) {
                    buyTotal += item.total;
                }    
            });
            
            if( currentLabel != '') {
                labels.push( currentLabel );
                buyTotals.push( buyTotal );
                sellTotals.push( sellTotal );
            }

            this.setState({
                sector: {
                    buyTotals: buyTotals, 
                    sellTotals: sellTotals, 
                    labels:labels
                }
              });
              
              this.createMarketOverviewPieChart();
              
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            this.setState({
            isLoaded: true,
            error
            });
        }
        );
    }

    createMarketOverviewPieChart = () =>  {
        const url = 'https://d9kzzxhxwa.execute-api.us-east-1.amazonaws.com/default/GetReportBuySellHoldTotals';
        fetch( url )
        .then(res => res.json())
        .then(
        (result) => {
            const List = result.List;
            var buy = 0;
            var sell = 0;
            var caution = 0;
            List.forEach( function( item ) {
                if( item.technical_rating == 1 ) {
                    sell += item.total;
                } else if( item.technical_rating == 4 || item.technical_rating == 5 ) {
                    buy += item.total;
                } else if( item.technical_rating == 3 ) {
                    caution += item.total;
                }    
            });

            this.setState({
                isLoaded: true,
                buyGrandTotal:buy, 
                sellGrandTotal:sell, 
                cautionGrandTotal:caution
              });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
            this.setState({
            isLoaded: true,
            error
            });
        }
        );
    }

    render = () => {
        const { error, isLoaded, buyGrandTotal, sellGrandTotal, cautionGrandTotal } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
          } else if (!isLoaded) {
            return <div>Loading...</div>;
          } else {
            return (
                
                <Container className="mb-40">
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-12"  >
                            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 class="h3 mb-0 text-gray-800">Featured undervalued stocks</h1>
                            </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-12"  >
                            <MSTCarousel dataUrl="https://bxzv1s05s2.execute-api.us-east-1.amazonaws.com/default/GetFeaturedUndervaluedCompanies" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-12"  >
                            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 class="h3 mb-0 text-gray-800">Market overview</h1>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-12" style={{display:'none'}}  >
                            <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Sector Overview</h6>
                            </div>
                            <div class="card-body custom-sector-overview">
                            
                                <SectorOverview buy={this.state.sector.buyTotals} sell={this.state.sector.sellTotals} labels={this.state.sector.labels} ></SectorOverview>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="mb-12"  >
                            <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Rating Breakdown</h6>
                            </div>
                            <div class="card-body custom-market-overview">
                                <MarketOverview buy={buyGrandTotal} sell={sellGrandTotal} caution={cautionGrandTotal} ></MarketOverview>
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            );
        }
    }

   
}
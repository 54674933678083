import React from 'react';
import ReactDOM from 'react-dom';
import Chart from "chart.js";

export default class SectorOverview extends React.Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);
        const buy = this.props.buy;
        const sell = this.props.sell;
        const labels = this.props.labels;
        
        this.state = {
            config: {
                type: "horizontalBar",
                
                data: {
                    datasets: [{
                        backgroundColor: 'green',
                        borderColor: 'green',
                        label: 'Buy',
                        data: buy
                    }, {
                        backgroundColor: 'red',
                        borderColor: 'red',
                        label: 'Sell',
                        data: sell,
            
                        // Changes this dataset to become a line
                        type: 'horizontalBar'
                    }],
                    labels: labels
                },
                options: {

                    scales: {
                        xAxes: [{
                            gridLines: {
                              display: false,
                              drawBorder: false
                            },
                            ticks: {
                              //maxTicksLimit: 6
                            },
                            maxBarThickness: 25,
                          }],
                          yAxes: [{
                            ticks: {
                              //min: 0,
                              //max: 15000,
                              //maxTicksLimit: 5,
                              padding: 10,
                              // Include a dollar sign in the ticks
                              callback: function(value, index, values) {
                                //return '$' + value;
                                return value;
                              }
                            },
                            gridLines: {
                              color: "rgb(234, 236, 244)",
                              zeroLineColor: "rgb(234, 236, 244)",
                              drawBorder: false,
                              borderDash: [2],
                              zeroLineBorderDash: [2]
                            }
                          }],
                    },

                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    
                    //Customize chart options
                    legend: {
                        display:'none',
                        position:'bottom', 
                        labels: {
                            padding: 20 
                          }
                    },
                }
            }
        };
    }

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");
        new Chart( myChartRef, this.state.config );
    }
    
    render = () => {
        return (
              <>  
              <canvas    
                    id="mySectorChart"
                    ref={this.chartRef} 
                    width="100%" height="100%"
                />
                
                </>
        );
    }
}


import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Image, Col, Card, Button } from 'react-bootstrap';
import AnalysisModal from './modals/AnalysisModal';



export default class MSTCarouselItem extends React.Component {
   
    constructor(props) {
        super(props);

        this.state = {
            tickerClicked: ''
        }

        this.onClickTicker = this.onClickTicker.bind(this);
        this.onCloseTickerModal = this.onCloseTickerModal.bind(this);

    }
   
    render = () => {
        var ratingStrengthedRounded = parseInt(this.props.item.technical_rating_strength, 10);;
        if( this.props.item.technical_rating_strength ) {
            ratingStrengthedRounded = ratingStrengthedRounded + '%';
        } else {
            ratingStrengthedRounded = '';
        }
        const technical_rating = parseInt( this.props.item.technical_rating );
        var ratingStyle = '';

        if( technical_rating == 5 || technical_rating == 4 ) {
            ratingStyle = 'colorGreen';
        } else if( technical_rating == 1 ) {
            ratingStyle = 'colorRed';
        } else if ( technical_rating == 3 ) {
            ratingStyle = 'colorYellow';
        } else {
            ratingStyle = '';
        }

        var logoImage = '';

        if( this.props.item.logo ) {
            logoImage = <div class="card-img-top-container" ><Image src={'https://eodhistoricaldata.com' + this.props.item.logo} /></div>
        } else {
            logoImage = <div class="card-img-no-image-space-filler" ></div>
        }

        if( this.state.tickerClicked ) {
            ReactDOM.render(
                <AnalysisModal item={this.props.item} key="AnalysisModal" ticker={this.state.tickerClicked} technicalRating={this.props.item.technical_rating} onModalClose={this.onCloseTickerModal}   />  
                ,document.getElementById('analysis-modal')
              );
        } else {
            ReactDOM.render(
                ''
                ,document.getElementById('analysis-modal')
              );
        }

        return (
            /*<tr>
                <td>+</td>
                <td>{this.props.item.country_dispay}</td>
                <td><a href={"/stock/" + this.props.item.ticker}>{this.props.item.ticker}</a></td>
                <td>{this.props.item.display_name}</td>
                <td>{this.props.item.sector}</td>
                <td>{this.props.item.industry}</td>
                <td><span className={sellStyle} ><span className={strongBuyStyle}>{this.props.item.technical_rating_display}</span></span>{this.props.item.technical_rating_strength}</td>
            </tr>*/

            
                <Card key={this.props.item.mst_assessment_direction_id} className="h-100 shadow-sm rounded custom-stock-summary swiper-slide" >
                <span className="rounded card-rating" ><span class={ratingStyle} >{this.props.item.technical_rating_display}</span></span>
                    <Card.Img variant="top" src="holder.js/100px180" style={{display:'none'}}  />
                    <Card.Body>
                        <div class="ticker" ><a onClick={this.onClickTicker} ref={this.props.item.ticker}  href="#" >{this.props.item.ticker}</a></div>
                        <div class="rating" ><span class={ratingStyle} >{ratingStrengthedRounded}</span></div>
                        {logoImage}
                        <p class="company-name">{this.props.item.display_name}</p>
                        <Card.Text>
                            <div class="section" >  
                                <label>Sector:</label><span class="value" >{this.props.item.sector}</span>
                            </div>
                            <div class="section" >  
                                <label>Industry:</label><span class="value" >{this.props.item.industry}</span>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            
            );
    }

    onClickTicker = (e) => {
        e.preventDefault();
        this.setState(
            {
                tickerClicked: e.target.innerHTML
            }
        );
        
      }

      onCloseTickerModal(e) {
        this.setState(
            {
                tickerClicked: ''
            }
        );

        return false;
        
      }
  
}
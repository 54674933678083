import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";

export default class SectorFilter extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            sectors: [
                {text:'All Sectors', value:' '},
                {text:'Basic Materials', value:'Basic Materials'},
                {text:'Communication Services', value:'Communication Services'},
                {text:'Consumer Cyclical', value:'Consumer Cyclical'},
                {text:'Energy', value:'Energy'},
                {text:'Financial Services', value:'Financial Services'},
                {text:'Healthcare', value:'Healthcare'},
                {text:'Industrials', value:'Industrials'},
                {text:'Real Estate', value:'Real Estate'},
                {text:'Technology', value:'Technology'},
                {text:'Utilities', value:'Utilities'}
            ]
            


    
        };

        

    }

    render = () => {
        const options = this.state.sectors.map((sector) =>
            <Dropdown.Item onClick={this.props.onSectorChanged.bind(null, sector.value, sector.text )} >{sector.text}</Dropdown.Item>
        );
            
        return (
            <DropdownButton className={ this.props.text != 'All Sectors' ? 'active' : '' }  id="dropdown-basic-button"  title={this.props.text} onClick={this.handleChange} >
                {options}        
            </DropdownButton>
          );
    }

    
}
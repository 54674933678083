import React from 'react';
import Swiper from 'react-id-swiper';
// Version <= 2.3.2
//import 'react-id-swiper/lib/styles/css/swiper.css';
// Version >= 2.4.0
import 'swiper/css/swiper.css';
//import { Swiper, Navigation, Pagination, Scrollbar } from 'swiper/dist/js/swiper.esm.js';
import MSTCarouselItem from './MSTCarouselItem'
import { Container, Row, Image, Col, Card, Button } from 'react-bootstrap';
import AnalysisModal from './modals/AnalysisModal';
import {withGetScreen} from 'react-getscreen';

export default class MSTCarousel extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            List : [],
            tickerClicked: '', 
            width:-1
        }

        this.onClickTicker = this.onClickTicker.bind(this);
        this.onCloseTickerModal = this.onCloseTickerModal.bind(this);
    }

    /**
   * Remove event listener
   */
  componentWillUnmount() {
    //window.removeEventListener("resize", this.updateCarousel.bind(this));
  }

    componentDidMount() {
        this.populateData();
        window.addEventListener("resize", this.updateCarousel.bind(this));
    }

    updateCarousel = () => {
        this.setState({ width: window.screen.width});
    }
    populateData = () => {
        var url = this.props.dataUrl
          console.log(url);
          fetch( url )
            .then(res => res.json())
            .then(
              (result) => {
                  this.setState({
                    isLoaded: true,
                    List: result.List, 
                  });
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
            )
    }

    render = () => {
        const { error, isLoaded } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } 
    const params = {
        // Provide Swiper class as props
        Swiper,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          slidesPerView: 'auto',
          spaceBetween: 20,    
          grabCursor:true,
          rebuildOnUpdate:true
        }
        
        const options = {mobileLimit: 600, tabletLimit:1200}    
        const windowSize = window.innerWidth;
        if (windowSize <= options.mobileLimit ) {
            params.slidesPerView = 1;
            //params.navigation = {};
        }
        else if ( windowSize <= options.tabletLimit ) {
            params.slidesPerView = 2;
        } else {
            params.slidesPerView = 4;    
        }
        
        var rows = [];
        this.state.List.forEach( function( item ) {
            rows.push(
                <MSTCarouselItem key={item.mst_assessment_direction_id} item={item} /> 
            );
            console.log('loading');
        });

        

        return (
            <Swiper {...params} >
                {rows}
            </Swiper>
        );
    } 

    onClickTicker = (e) => {
        e.preventDefault();
        this.setState(
            {
                tickerClicked: e.target.innerHTML
            }
        );
        
      }

      onCloseTickerModal(e) {
        this.setState(
            {
                tickerClicked: ''
            }
        );

        return false;
        
      }
}
 

//export default withGetScreen(MSTCarousel, options);
import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, Button } from "react-bootstrap";

export default class RatingFilter extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            ratings: [
                {value:'buy', text:'Buy'}, 
                {value:'sell', text:'Sell'}, 
            ]
    
        };
    }

    render = () => {
        

        return (
            <>
                <div role="group" className="button btn-group custom-buy-button" >
                <Button variant="primary" className={this.props.selected == 'buy' ? 'active' : '' }  block onClick={this.props.onRatingChanged.bind(null, 'buy' )} >
                    Buy
                </Button>
                </div>
                <div role="group" className="button btn-group custom-sell-button" >
                <Button variant="primary" className={this.props.selected == 'sell' ? 'active' : '' }  block onClick={this.props.onRatingChanged.bind(null, 'sell' )} >
                    Sell
                </Button>
                </div>
            </>
        );
    }
}
import React from 'react';
import logo from './logo.svg';
import './App.scss';
import GridView from './components/GridView';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Sidebar from './layouts/Sidebar';
import Header from './layouts/Header';
import Overview from './Overview';
import About from './About';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import MSTModal from './layouts/MSTModal';


function App() {
  return (
      <Router>
      
        <div id="wrapper">
          <div id="analysis-modal" ></div>
          <Sidebar></Sidebar>
          <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
              <Header></Header>
              
              <Switch>
                <Route path="/stocks" >
                  <Container>
                    <Row>
                      <Col sm={12} className="filter-table-container" >
                        <section id="filter-table" class="filters" >
                            
                        </section>
                      </Col>
                    </Row>
                  </Container>
                  <GridView dataUrl="https://lqmgjutzdj.execute-api.us-east-1.amazonaws.com/default/GetCompanies" ></GridView>
                </Route>
                <Route path="/index.html">
                  <Overview />
              </Route>
              <Route path="/">
                  <Overview />
                </Route>
                <Route path="/dashboard">
                  <Overview />
                </Route>
              
                <Route path="/about">
                  <About />
                </Route>
              </Switch>

            </div>
    <footer class="sticky-footer bg-white">
 <div class="container my-auto">
   <div class="copyright text-center my-auto">  
     <span>Copyright © mySmartTrader</span>
   </div>
 </div>
</footer>
  </div>
  
</div>



        
                  
          
        </Router>
    );
}


export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import PriceChart from './charts/PriceChart';
import RatingChart from './charts/RatingChart';
import Analysis from '../Analysis.js';



export default class AnalysisModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
          error: null,
          isLoaded: false,
          prices:[], 
          ratings:[], 
          limit:0
        }
    };

    componentDidMount() {
      this.getRatingData();
    }

    getRatingData = () => {
      
      //const url = 'http://38.88.104.152/home/getRatingHistory?ticker=' + this.props.ticker;
      const url = 'https://mysmarttraderassessments.s3.amazonaws.com/' + this.props.ticker + '.txt';
      fetch( url )
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            ratings :  result.List
          });
          this.getPriceData(this.state.ratings.length);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )    
      
    }
    


    getPriceData = (limit) => {
      
      //const url = 'http://38.88.104.152/home/getStockHistory?ticker=' + this.props.ticker + "&limit=" + limit;
      //const url = 'http://38.88.104.152/History/' + this.props.ticker + '.txt';
      const url = 'https://mysmarttraderhistory.s3.amazonaws.com/' + this.props.ticker + '.txt';
      
      fetch( url )
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            prices :  result.List, 
            limit:limit
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )    
      
    }
    
    render = () => {
        const { error, isLoaded,  prices, ratings } = this.state;
        var show = false;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return '';
        } else {
          var priceList = [];
          var dateList = [];
          var minPrice = 999999;
          var maxPrice = 0;
          //this.state.prices.reverse().splice(1,4).forEach( function( item ) {
          //this.state.prices.splice(0,this.state.limit).reverse().forEach( function( item ) {
          this.state.prices.reverse().forEach( function( item ) {
            priceList.push(item.adj_close);
            maxPrice = Math.max( maxPrice, item.adj_close );
            minPrice = Math.min( minPrice, item.adj_close );
            var numb = item.date_cr.match(/\d/g);
            numb = numb.join("");
            dateList.push(numb);
          });

          var ratingsList = [];
          var ratingsDateList = [];
          this.state.ratings.forEach( function( item ) {
            var rating_display = 'CAUTION';
            if( item.technical_rating == 5 || item.technical_rating == 4 ) {
              rating_display = 'BUY';
            } else if( item.technical_rating == 1 ) {
              rating_display = 'SELL';
            }
            ratingsList.push( rating_display );
            /*var numb = item.timestamp.match(/\d/g);
            numb = numb.join("");
            ratingsDateList.push(numb);*/
          });
       
        }

        if( this.props.ticker) {
          var show = true;
        } 

        return (
          <Modal size="lg"
          show={show}
          onHide={this.props.onModalClose.bind(null)} 
          aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {this.props.ticker}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xl={3} lg={3} md={12} sm={12} xs={12} className="mt-4" >
                  <Analysis item={this.props.item} ticker={this.props.ticker} technicalRatingStrength={this.props.technicalRatingStrength} technicalRating={this.props.technical_rating} />
                </Col>
                <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                  <PriceChart dates={dateList} prices={priceList} max={maxPrice} min={minPrice} /> 
                  <RatingChart ratings={ratingsList} technicalRating={this.props.technicalRating} /> 
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
          );
    }
}

    
/** bootstrap 4 start **/
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
/** bootstrap 4 end **/
/**
 * font awesome
 * 
 */
import './css/fontawesome-free/css/all.min.css';



import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import './css/sb-admin-2.min.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

document.title = 'mySmartTrader'

const trackingId = "UA-18901983-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  //userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})

ReactDOM.render(
<App />, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";

export default class About extends React.Component {
    
    constructor(props) {
        super(props);

       
        
    }


    render = () => {

        

        return (
            
            '<h1>About Page</h1>'
          );
    }

   
}
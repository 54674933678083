import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, Button } from "react-bootstrap";

export default class RefreshFilter extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            title:"Reset",
        };
    }

    render = () => {
        return (
            <div role="group" className="button btn-group" >
            <Button variant="primary" block onClick={this.props.onRefreshChanged.bind(null )} >
                {this.state.title}
            </Button>
            </div>
          );
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";

export default class CountryFilter extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            countries: [
                {text:'All Countries', value:''}, 
                {text:'Australia', value:'AUSTRALIA'}, 
                {text:'Belgium', value:'BELGIUM'},
                {text:'Canada', value:'CAD'}, 
                {text:'France', value:'FRANCE'}, 
                {text:'Holland', value:'HOLLAND'}, 
                {text:'Hong Kong', value:'CHINA'}, 
                {text:'Italy', value:'ITALY'}, 
                {text:'Signapore', value:'SIGNAPORE'},
                {text:'Spain', value:'SPAIN'},
                {text:'United Kingdom', value:'UNITED KINGDOM'}, 
                {text:'United States', value:'US'}, 
                
            ], 

        };
    }


    render = () => {

        const options = this.state.countries.map((country) =>
            <Dropdown.Item  onClick={this.props.onSectorChanged.bind(null, country.value, country.text )} >{country.text}</Dropdown.Item>
        );

        return (
            
            <DropdownButton className={ this.props.text != 'All Countries' ? 'active' : '' } id="dropdown-basic-button"  title={this.props.text} onClick={this.handleChange} >
                {options}        
            </DropdownButton>
          );
    }

   
}
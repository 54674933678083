import React from 'react';
import ReactDOM from 'react-dom';
import ZingChart from 'zingchart-react';
import 'zingchart-react/dist/modules/zingchart-depth.min.js';


export default class RatingChart extends React.Component {
    
    constructor(props) {
        super(props);
        var graphColor = '#4e73df';
        /*if(this.props.technicalRating == 5 || this.props.technicalRating == 4 ) {
            graphColor = '#18cd57';
        } else if( this.props.technicalRating == 1 ) {
            graphColor = '#f30131';
        }*/

        var values = this.props.ratings;
        
        this.state = {
          config: {
            type: 'line',
            height: 120,
            x: 0,
            y: 0,
            backgroundColor: "#fff",
            plotarea: {
                margin: "10 20 20 80"
            },
            /*source: {
              text: "nasdaq.com",
              fontColor: "#ddd",
              fontFamily: "Helvetica,Arial,sans-serif"
            },*/
            tooltip: {
                visible: false,
                text: "Rating: %v",
                
                borderColor: "transparent"
            },
            zoom: {
                shared: true
            },
            crosshairX: {
                shared: true,
                scaleLabel: {
                    visible: false
                },
                plotLabel: {
                    
                    backgroundColor: "#fff",
                    fontColor: graphColor,
                    text: "Rating: %v",
                    y: 0
                }
            },
            plot: {
                marker: {
                    visible: false
                }

            },
            scaleX: {
                visible: false,
                zooming: false
            },
            scaleY: {
                visible: true,
                zooming: false,
                values: ["SELL", "CAUTION", "BUY"],
                item: {
                    fontColor: graphColor,
                    /*fontFamily: "Helvetica,Arial,sans-serif",
                    fontSize: 10,*/
                    

                },
                guide: {
                    visible: true,
                    lineStyle: 'dashed'
                    
                }
            },
            series: [{
                values: values,
                text: "Volume",
                lineColor: graphColor,
                backgroundColor: graphColor,
            }]
          }
        }
    };
    
    render = () => {
       
        return (
            <ZingChart height="160" data={this.state.config}/>
        );
    }
}


import React from 'react';
import ReactDOM from 'react-dom';

export default class DataTable extends React.Component {
    
    
    render = () => {
        if( this.props.currentPage < this.props.Size ) {
            var li = [];
            var pageCount = this.props.Size;
            li.push(
                <li key="LoadMore"><button href="#" class="btn-primary" onClick={this.props.onPageChanged.bind(null, "next" )}>Load More</button></li> );
        }
        return (
        <ul className="pagination">{li}</ul>);
        
        }
}


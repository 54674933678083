import React from 'react';
import ReactDOM from 'react-dom';


export default class Sidebar extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'Dashboard'
        }

        
    }
   
    
    render = () => {
        const logo = require('../images/logo.png');
        const dashboardClasses = window.location.pathname == '/dashboard' || window.location.pathname == '/index.html' || window.location.pathname == '/' ? 'nav-item active' : 'nav-item';
        const stocksClasses = window.location.pathname == '/stocks' ? 'nav-item active' : 'nav-item';
        const aboutClasses = window.location.pathname == '/about' ? 'nav-item active' : 'nav-item';
        

        return (
            
                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                        <div class="sidebar-brand-text mx-3"><img className="Logo" src={logo} alt="logo"  /></div>
                    </a>
                    <hr class="sidebar-divider" />
                    <div class="sidebar-heading">
                        Menu
                    </div>
                    <li className={dashboardClasses} >
                        <a  class="nav-link" href="/dashboard">
                        <i class="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                    </li>
                    <li className={stocksClasses} >
                        <a class="nav-link" href="/stocks">
                        <i class="fas fa-fw fa-chart-area"></i>
                        <span>Stocks</span>
                        </a>
                    </li>
                    <li style={{display:'none'}} className={aboutClasses} >
                        <a class="nav-link" href="/about">
                        <i class="fas fa-fw fa-file"></i>
                        <span>About</span>
                        </a>
                    </li>
                </ul>
            
        );
    }
}
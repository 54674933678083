import React from 'react';
import ReactDOM from 'react-dom';
import ZingChart from 'zingchart-react';
import 'zingchart-react/dist/modules/zingchart-depth.min.js';


export default class PriceChart extends React.Component {
    
    constructor(props) {
        super(props);
        var dates = this.props.dates;
        var values = this.props.prices;
        var minPriceMax = this.props.min.toFixed(4);
        var maxPriceMax = this.props.max.toFixed(4)*1.3;
        var spreadPriceMax = ((maxPriceMax - minPriceMax) / 4);
        spreadPriceMax = spreadPriceMax.toFixed(4);

        this.state = {
          config: {
            type: 'area',
            /*title:{  
                "text":"Tracks Stock Price with Rating"  
            },  
            subtitle:{  
                "text":"This stock has a 90% chance of going down"  
            },*/  
            backgroundColor: "#fff",
            height: 240,
            x: 0,
            y: 0,
            adjustLayout: true,
            crosshairX: {
                shared: true,
                plotLabel: {
                    backgroundColor: "#fff",
                    fontColor: "#333",
                    text: "Close: %v",
                    y: 0,
                },
                scaleLabel: {
                    fontColor: "#333",
                    backgroundColor: "white",
                }
            },
            text: "GOOG",
            /*title: {
              fontColor: "black",
              fontFamily: 'Helvetica,Arial,sans-serif',
              fontSize: 30,
              align: 'left',
              offsetX: 10
            },*/
            zoom: {
                shared: true
            },
            plotarea: {
                /*margin: "60 50 40 50"*/
                margin: "40 20 20 80"
            },
            plot: {
                marker: {
                    visible: false
                }
            },
            tooltip: {
                text: "Close: %v",
                backgroundColor: "#BBB",
                borderColor: "transparent"
            },
            scaleY: {
                guide: {
                    visible: true,
                    lineStyle: 'dashed'
                    
                },
                /*values: "560:600:20",*/
                /*values: "300:2000",*/
                /*values: values,*/
                
                values: minPriceMax + ':' + maxPriceMax + ':' + spreadPriceMax,
                format: '$%v',
                item: {
                    fontColor: "#333",
                    /*fontFamily: "Helvetica,Arial,sans-serif",
                    fontSize: 10*/
                }
            },
            scaleX: {
                guide: {
                    visible: false,
                    lineStyle: 'solid',
                    lineColor: "red"
                },
                values: dates,
                transform: {
                    type: 'date',
                    all: '%m/%d/%y'
                },
                /*zooming: {
                    shared: true
                },*/
                zooming: false,
                item: {
                    fontColor: "#fff",
                    /*fontFamily: "Helvetica,Arial,sans-serif",
                    fontSize: 10*/
                }
            },
            series: [{
                values: values,
                lineColor: "#333",
                backgroundColor: "#333",
            }],
          }
        }
    };
    
    render = () => {
       
        return (
            <ZingChart height="240" data={this.state.config}/>
        );
    }
}

